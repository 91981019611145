import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="lightBg" style={{padding: '50px 0', marginBottom: 50}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">План платежей</h1>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6">
              <PriceBox>
                <span className="font20">Первоначальный взнос</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-6">
              <PriceBox>
                <span className="font20">Июнь 2023</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-6">
              <PriceBox>
                <span className="font20">Декабрь 2023</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-6">
              <PriceBox>
                <span className="font20">Июнь 2024</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-6">
              <PriceBox>
                <span className="font20">Февраль 2025</span>
                <p className="font25 semiBold">15%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-6">
              <PriceBox>
                <span className="font20">Сентябрь 2025</span>
                <p className="font25 semiBold">15%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-6">
              <PriceBox>
                <span className="font20">Апрель 2026</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-6">
              <PriceBox>
                <span className="font20">Октябрь 2026</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-6  col-sm-offset-3">
              <PriceBox>
                <span className="font20">По завершении строительства</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Планировки и цена апартаментов</h1>
            <p>
              В Creek Waters Вы можете выбрать апартаменты с 1-й, 2-мя, 3-мя или 4-мя спальнями, пентхаус с 5-ю спальнями или таунхаус с 3-мя спальнями.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                price="Апартаменты с 1 спальней"
                title="От $435,777 до $572,000"
                offers={[
                  { name: "Количество квартир: 185", cheked: true },
                  { name: "Площадь: от 70 до 88 кв.м.", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="roller"
                price="Апартаменты с 2 спальнями"
                title="От $599,000 до $844,300"
                offers={[
                  { name: "Количество квартир: 175", cheked: true },
                  { name: "Площадь: от 104 до 138 кв.м.", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="roller"
                price="Апартаменты с 3 спальнями"
                title="От $844,300 до $1,143,915"
                offers={[
                  { name: "Количество квартир: 67", cheked: true },
                  { name: "Площадь: от 148 до 167 кв.м.", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBoxBig>
              <PricingTable
                icon="roller"
                price="Апартаменты с 4 спальнями"
                title="От $1,688,600"
                offers={[
                  { name: "Количество квартир: 22", cheked: true },
                  { name: "Площадь: 223 кв.м.", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBoxBig>
            <TableBoxBig>
              <PricingTable
                icon="roller"
                price="Пентхаус с 5 спальнями и бассейном"
                title="От $5,120,000"
                offers={[
                  { name: "Количество квартир: 1", cheked: true },
                  { name: "Площадь: 762 кв.м.", cheked: true }
                ]}
                action={() => alert("clicked")}
              />
            </TableBoxBig>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;
const TableBoxBig = styled.div`
  width: 47%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;
const PriceBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgb(200 200 200)  ;
  -webkit-box-shadow: 0 0 5px rgb(200 200 200)  ;
  -moz-box-shadow: 0 0 5px rgb(200 200 200)  ;
  padding: 10px 20px 5px;
  align-items: center;
  margin-bottom: 15px;
`;




