import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoIcon from "../assets/svg/Logo";
import FullButton from '../components/Buttons/FullButton.jsx';
import HeaderImage from '../assets/img/header-img.jpg';
import Dots from '../assets/svg/Dots.jsx';
import { Link as RouteLink } from 'react-router-dom';

export default function ThankYou() {
  const [y, setY] = useState(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);


  return (
    <>
      <HeaderWrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px", borderBottom: '1px solid #b0b0b0' } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <LogoIcon />
          </Link>
        </NavInner>
      </HeaderWrapper>
      <ContentWrapper id="home" className="container flexSpaceCenter">
        <LeftSide className="flexCenter">
          <div>
            <h1 className="extraBold font40">Спасибо за ваш запрос</h1>
            <HeaderP className="font18 semiBold">
              Ваш запрос успешно выполнен. Мы свяжемся с вами в ближайшее время
            </HeaderP>
            <RouteLink to={'/'}>
              <FullButton title="Обратно"/>
            </RouteLink>
          </div>
        </LeftSide>
        <RightSide>
          <ImageWrapper>
            <Img className="radius8 animate pop" src={HeaderImage} alt="office" style={{zIndex: 9}} />

            <DotsWrapper>
              <Dots />
            </DotsWrapper>
          </ImageWrapper>
          <GreyDiv className="lightBg"></GreyDiv>
        </RightSide>
      </ContentWrapper>
    </>
  );
}

const HeaderWrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const ContentWrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 500px;
    padding-top: 60px;
  }
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 20px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 30px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  //max-width: 190px;
  margin-top: 24px;
  display: flex;
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
  .animate.pop {
    animation-duration: 0.5s;
    animation-name: animate-pop;
    animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
  }

  @keyframes animate-pop {
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 50%;
    height: auto;
  }
`;
